import React from 'react'
import Image from 'components/Image'
import Row from 'components/Row'
import Text from 'components/Text'
import GatedResource from 'templates/GatedResource'
import testimonialsImage from './images/testimonials.png'

export default function Resource3PillarsOfHealthyAging() {
  const title = '3 pillars of healthy aging'

  return (
    <GatedResource
      title={
        <>
          A Bold aging guide:
          <br />
          {title}
        </>
      }
      entity="guide"
      subtext="3 keys for healthy aging and how Bold can help."
      resource={title}>
      <Row>
        <Text size="xlarge">Here's what people are saying about Bold:</Text>
      </Row>
      <Image src={testimonialsImage} alt="Bold community testimonials" />
    </GatedResource>
  )
}
